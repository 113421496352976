@import '~antd/dist/antd.css';
@import './CustomStyles/Antd/button.scss';
@import './CustomStyles/Antd/form.scss';
@import './CustomStyles/Antd/input.scss';
@import './CustomStyles/Antd/select.scss';
@import './CustomStyles/Antd/collapse.scss';
@import './CustomStyles/Antd/divider.scss';
@import './CustomStyles/Antd/tooltip.scss';
@import './CustomStyles/Antd/alert.scss';
@import './CustomStyles/Antd/tabs.scss';
@import './CustomStyles/Antd/upload.scss';
@import './tailwind.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .w-230 {
    width: 230px;
  }
}

body {
  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  min-height: 100vh;

  #root {
    height: 100%;
    width: 100%;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: unset !important;
  }
  .ant-typography h1,
  h2,
  h3,
  h4,
  h5 {
    color: var(--primary_text);
    &.page-title {
    }

    &.page-sub-title {
      font-size: 20px;
      color: var(--secondary_blue) !important;
      margin-top: 0 !important;
      margin-bottom: 20px;
      font-weight: 400;
    }
  }
}

.page-table {
  width: 100%;
  padding: 0 10px;
}

.ant-modal-mask {
  background: rgba(black, 0.3) !important;
}

.ant-modal {
  .ant-modal-header {
    // border-radius: 10px 10px 0 0;
    color: var(--primary_text);
  }

  .ant-modal-body {
  }

  .ant-modal-content {
  }

  .ant-modal-footer {
  }
}

.ant-picker {
  border-radius: var(--br_DEFAULT) !important;
  height: 32px;
  border: 1px solid var(--border) !important;
  &-focused {
    box-shadow: unset !important;
  }
  .ant-picker-input {
    &-active {
    }
  }
}

.ant-picker-ranges {
  .ant-picker-preset > .ant-tag {
    background: var(--brand_primary) !important;
    border: none;
    color: var(--white) !important;
    padding: 5px 20px;
    border-radius: 20px;
  }

  .ant-picker-ok {
    margin: 10px;
  }
}

code {
  font-family: 'Montserrat', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  margin-top: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--border);
  border-radius: 5px;
  transition: 0.2s;
  height: 3px !important;
  width: 3px !important;
  &:hover {
    background: var(--brand_primary);
  }
}

::-webkit-scrollbar-track {
  margin: 3px;
  background: none;
  border-radius: 5px;
}

.ant-modal-confirm-body {
  .anticon {
    display: none;
  }
  .action-prompt-popup {
    .anticon {
      display: none;
    }

    .prompt-description {
      color: var(--gray_9);
      font-size: 16px;
    }
  }
}

.ant-message {
  .ant-message-notice-content {
    border-radius: 4px;
    padding: 0;
    overflow: hidden;

    .ant-message-custom-content {
      padding: 12px 15px;
      color: var(--black);
      font-weight: 700;

      // &.ant-message-success {
      //   background: var(--positive);
      // }

      .anticon {
        top: 0;
        font-size: 22px;
      }
    }
  }
}

.anticon {
  vertical-align: 0 !important;
}

.ant-image-preview-img-wrapper {
  display: flex;
  justify-content: center;
}

// Antd Empty Component
.ant-empty {
  padding-top: var(--sp_lv_3);
  padding-bottom: var(--sp_lv_3);
}

.ant-checkbox-wrapper {
  // state right-padding
  &.non-padding {
    span.ant-checkbox + span {
      padding-right: unset !important;
      padding-left: unset !important;
    }
  }
}

.ant-popover {
  .ant-popover-content {
    .ant-popover-inner {
      .ant-popover-inner-content {
        display: flex;
        justify-content: center;
        padding: var(--sp_lv_3);
        align-items: center;
        box-shadow: var(--sd_bot_2);
      }
    }
  }
  &.non-arrow {
    .ant-popover-arrow {
      display: none !important;
    }
  }
  &.no-padding {
    .ant-popover-arrow {
      display: none !important;
    }
    .ant-popover-content {
      .ant-popover-inner {
        .ant-popover-inner-content {
          padding: 0px !important;
        }
      }
    }
  }
}

.items-end {
  align-items: flex-end !important;
}

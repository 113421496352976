.ant-alert {
  &-error {
    border: none;
    border-radius: var(--br_DEFAULT) !important;
    margin: var(--sp_lv_2) 0 !important;
    border-color: var(--red_6) !important;
    background: var(--red_1) !important;
    .anticon,
    .ant-alert-message {
      color: var(--red_6) !important;
      // color: var(--white) !important;
      // background-color: var(--white);
    }

    .anticon-close-circle {
      svg {
        fill: var(--white) !important;
      }
    }
  }
}

.ant-tooltip {
  .ant-tooltip-arrow-content {
  }

  .ant-tooltip-inner {
    padding: 5px 10px;
    color: var(--white);
    font-weight: 600;
    border-radius: 5px;
    a {
      font-size: 14px;
      font-weight: 500;
      color: var(--default_text_color) !important;
    }
  }
}

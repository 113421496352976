.ant-tabs {
  .ant-tabs-nav {
    color: var(--white) !important;
    border-bottom: 1px solid var(--divider) !important;
    margin: unset !important;
    margin-bottom: 0 !important;
    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        .ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            font-weight: var(--fw_semibold) !important ;
            color: var(--primary_text) !important;
          }
        }

        .ant-tabs-tab {
          padding: 0px !important;
          margin-bottom: var(--sp_lv_3) !important;
        }

        .ant-tabs-nav-operations {
          border-right: 1px solid var(--white) !important;
        }
        .ant-tabs-ink-bar {
          background: var(--brand_primary) !important;
        }
        .ant-tabs-tab-btn {
          color: var(--primary_text) !important;
        }
      }
    }
  }
  &--white-mode {
    padding-top: var(--sp_lv_3) !important;
    padding-left: var(--sp_lv_6) !important;
    .ant-tabs-nav {
      border-bottom: unset !important;
      .ant-tabs-nav-wrap {
        .ant-tabs-nav-list {
          .ant-tabs-tab {
            .ant-tabs-tab-btn {
              span {
                color: var(--primary_text) !important;
              }
            }
          }
        }
      }
    }
  }

  &.ant-tabs--mini {
    width: 100% !important;
    .ant-tabs-tab {
      padding: 5px 2px !important;
      justify-content: center !important;
      border: none !important;
      border-top: 2px solid var(--gray_3) !important;
      margin-bottom: 0px !important;
      &-active {
        background: var(--white);
        border-top: 2px solid var(--brand_primary) !important;
      }
    }
    .ant-tabs-nav-wrap {
      width: 100% !important;
    }
    .ant-tabs-nav-list {
      width: 100% !important;
      display: grid !important;
      grid-template-columns: 1fr 1fr 1fr !important;
    }
    .ant-tabs-content-holder {
      min-height: 206px !important;
      padding-top: var(--sp_lv_2) !important;
    }
  }

  &.margin-content-6 {
    .ant-tabs-nav {
      .ant-tabs-nav-wrap {
        // margin-bottom: var(--sp_lv_6);
        .ant-tabs-nav-list {
        }
      }
    }
  }
}

.ant-collapse {
  border: none !important;

  &-header {
    font-weight: 600 !important;
  }
  &-item {
    border: none !important;
    background: var(--white) !important;
  }
}

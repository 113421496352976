.ant-select {
  // Todo: height 40 should be default
  &.height-40 {
    height: 40px !important;

    .ant-select-selector {
      height: 40px !important;
      border: unset !important;
    }
  }
  .ant-select-selector {
    // border: unset !important;
  }

  &.no-border {
    outline: unset !important;
    .ant-select-selector {
      height: 30px !important;
      border: unset !important;
    }
  }
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: var(--border) !important;
  box-shadow: none !important;
}

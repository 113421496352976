.ant-btn {
  border-radius: 4px !important;
  box-shadow: none !important;
  background: none !important;
  min-height: 40px !important;
  padding: 8px 32px !important;
  border: none;

  &:hover {
    filter: saturate(1.1);
    transition: 0.2s;
  }

  &:active {
    opacity: 0.5;
  }

  @mixin primary-btn {
    background: var(--brand_primary) !important;
    color: var(--white) !important;
    font-weight: 600 !important;
    text-shadow: none !important;
    min-width: 90px;

    &[disabled] {
      opacity: 0.5;
    }
  }

  &.ant-btn-primary {
    @include primary-btn;
  }

  &.ant-btn-dangerous {
    @include primary-btn;
    background: var(--negative) !important;
  }

  &.ant-btn-secondary {
    color: var(--white) !important;
    background-color: var(--secondary_blue) !important;
    border: 1px solid var(--faded_blue) !important;
  }
  &.ant-btn-sm {
    min-width: unset !important;
    height: 28px !important;
    padding: 5px 8px !important;
    font-size: 12px;
    min-height: unset !important;
  }

  &.ant-btn-circle {
    border-radius: 50% !important;
    width: 38px !important;
    height: 38px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.ant-btn[disabled] {
  cursor: not-allowed !important;
  color: rgba(0, 0, 0, 0.25) !important;
  background: transparent !important;
  border-color: transparent !important;
  text-shadow: none !important;
  box-shadow: none !important;
}

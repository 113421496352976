.ant-form {
  .ant-row.ant-form-item {
    .ant-form-item-label {
      padding: 0px !important;
      label {
        font-size: var(--fs_base);
        line-height: var(--fh_base);
        font-weight: var(--fw_semibold);
        color: var(--secondary_text);
        margin-bottom: var(--sp_lv_2) !important;
      }
    }
  }
}
